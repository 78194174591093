import React from "react";
import StaticImage from "gatsby-image";
import Markdown from "react-markdown";

import * as styles from "./BlockBeziehungZuAktivitaet.module.scss";
import PreviewAktivitaetPublikation from "../PreviewAktivitaet/PreviewAktivitaetPublikation";

function BlockBeziehungZuAktivitaet({ aktivitats, Content, Headline, Image }) {
  return (
    <div
      className={`${styles.underlineLinks} underline-links lg:grid text-gray-700 mb-12 leading-8 grid-flow-col-dense lg:grid-cols-3 md:gap-10`}
    >
      <div
        style={{ gridColumn: "3" }}
        data-sal="slide-up"
        data-sal-delay={`200`}
        data-sal-easing="ease-in-out"
        data-sal-duration="5000"
        className="mb-4"
      >
        <h2 className="font-header leading-relaxed mb-6 text-2xl">
          {Headline}
        </h2>
        <Markdown>{Content}</Markdown>
      </div>
      <div className="grid md:grid-cols-2 lg:col-span-2 gap-2">
        {aktivitats &&
          aktivitats.map((aktivitaet) => {
            let { Slug, Titel, Kurzbeschreibung, Datum, Autoren } = aktivitaet;
            return (
              <PreviewAktivitaetPublikation
                slug={Slug}
                titel={Titel}
                kurzbeschreibung={Kurzbeschreibung}
                datum={Datum}
                autoren={Autoren}
                {...aktivitaet}
              />
            );
          })}
      </div>
    </div>
  );
}

export default BlockBeziehungZuAktivitaet;
