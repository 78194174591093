import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import Markdown from "react-markdown";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Button from "../components/Button/Button";
import AutorDetails from "../components/AutorDetails";
import ProfilfeldLinks from "../components/ProfilfeldLinks";

import BlockBeziehungZuAktivitaet from "../components/BlockBeziehungZuAktivitaet";
import BlockTextImage from "../components/BlockTextImage";

import sortAktivitaetskategories from "../util/sortAktivitaetskategories";

import * as styles from "./aktivitaetTemplate.module.scss";

function AktivitaetTemplate({ data }) {
  const post = data.strapiAktivitaet;

  return (
    <Layout>
      <SEO
        keywords={[`${post.Titel}`, `Institut`, `Ethik`, `Management`]}
        title={post.Titel}
        description={
          post.Kurzbeschreibung && post.Kurzbeschreibung > 150
            ? post.Kurzbeschreibung.substring(0, 150)
            : post.Kurzbeschreibung
        }
      />
      <div className="container mx-auto mt-12">
        <article className="grid gap-8 lg:grid-cols-5 grid-cols-1 mb-8">
          {post.FeaturedImage ? (
            <div className="featuredImage md:col-span-2 col-span-5">
              <Img
                fluid={post.FeaturedImage.localFile.childImageSharp.fluid}
              ></Img>
            </div>
          ) : (
            ""
          )}
          <div className="col-span-5 md:col-span-3">
            <div className="meta mb-4 shadow-lg p-4 md:p-8">
              <p className="opacity-75 mb-4">
                {post.aktivitatskategories &&
                  sortAktivitaetskategories(post.aktivitatskategories)
                    .map((cat) => cat.Titel)
                    .join(" | ")}
              </p>
              <h1 className="font-header break-words text-2xl">{post.Titel}</h1>
              {post.kompetenzfelds && (
                <div className="mb-6 mt-6">
                  <p className="font-bold">
                    {post.kompetenzfelds.length > 1 ? "Profilfelder" : null}
                    {post.kompetenzfelds.length === 1 ? "Profilfeld" : null}
                  </p>
                  <ProfilfeldLinks edges={post.kompetenzfelds} />
                </div>
              )}
              <Markdown
                className={`${styles.underlineLinks} font-bold mb-6 mt-4`}
              >
                {post.Kurzbeschreibung}
              </Markdown>
              <Meta post={post} />
            </div>
            <div className="mt-8 md:mt-0 md:p-8">
              <Markdown className="prose">{post.Content}</Markdown>
            </div>
          </div>
          <div className="width-full col-span-5">
            {post.ContentBlocks &&
              post.ContentBlocks.map((block) => {
                console.log(block);
                if (block.strapi_component === "layout.block-text-image") {
                  return <BlockTextImage key={block.id} {...block} />;
                }
                if (
                  block.strapi_component === "layout.beziehung-zu-aktivitaet"
                ) {
                  return (
                    <BlockBeziehungZuAktivitaet key={block.id} {...block} />
                  );
                }
                //return <BlockTextImage key={block.id} {...block} />;
              })}
          </div>
        </article>
      </div>
    </Layout>
  );
}

function Meta({ post, mailIcon }) {
  return (
    <div>
      {post.Aktivitaetslink && (
        <Button
          target="__blank"
          className="mb-6 text-white bg-primary w-1/3 py-2 pl-2"
          key={post.Aktivitaetslink}
          href={post.Aktivitaetslink}
        >
          ansehen
        </Button>
      )}

      {post.imErscheinen && (
        <div
          target="__blank"
          className="mb-6 text-white bg-primary w-1/3 py-2 pl-2 font-light"
          key={post.Aktivitaetslink}
          href={post.Aktivitaetslink}
        >
          im Erscheinen
        </div>
      )}

      {post.people && (
        <div className="mb-4 flex flex-col space-y-2">
          {post.people.map((person) => (
            <AutorDetails person={person} key={person.Nachname} />
          ))}
        </div>
      )}
      {
        post.kooperationspartner && console.log(post.kooperationspartner)
        /* (
        <div>
          {post.kooperationspartner.map((partner) => (
            <div>{partner.Name}</div>
          ))}
        </div>
      ) */
      }
    </div>
  );
}

AktivitaetTemplate.propTypes = {
  data: PropTypes.any,
};

export const query = graphql`
  query ($slug: String!) {
    strapiAktivitaet(Slug: { eq: $slug }) {
      Titel
      Content
      imErscheinen
      kooperationspartner
      people {
        Vorname
        Nachname
        Xing
        LinkedIn
        eMail
        Kurzvita
        Profilbild {
          localFile {
            childImageSharp {
              fixed(width: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
      Aktivitaetslink
      Kurzbeschreibung
      FeaturedImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      ContentBlocks
      saules {
        Slug
        Titel
      }
      kompetenzfelds {
        Slug
        Titel
      }
      aktivitatskategories {
        Slug
        Titel
      }
    }
  }
`;

export default AktivitaetTemplate;

/** 
        ... on BeziehungZuAktivitaetFragment

        __typename
        ... on ComponentDefaultClosingperiod {
          id
          Content
          BildLinks
          Heading
          Image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        id
        Content
        BildLinks
        Heading
        Image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        } */
